<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>场景+</el-breadcrumb-item>
            <el-breadcrumb-item>全局画像</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="top">
         <el-col :span="16" class="top-left">
            <el-row class="top-left-bg">
               <el-row class="title">使用数据统计</el-row>
               <el-col :span="15" class="top-left-pie">
                  <el-row id="top-left-pie" style="width: 100%; height: 470px;"></el-row>
               </el-col>
               <el-col :span="9" class="top-left-list">
                  <el-row>
                     <p v-for="(item, index) in useData" :key="index"><b>{{item.name}}：</b>使用{{item.value}}次</p>
                  </el-row>
               </el-col>
            </el-row>
         </el-col>
         <el-col :span="8" class="top-right">
            <el-row class="top-right-bg" style="padding-bottom: 10px">
               <el-row class="top-right-top">
                  <el-row class="title">数据概况</el-row>
                  <el-row class="content">
                     <p>设备总数量(个)：{{surveyData.total}}</p>
                     <p>系统运行时间(分钟)：{{surveyData.deviceRuntime}}</p>
                     <p>离线率：{{surveyData.offlinerate}}</p>
                     <p>离线设备计数：{{surveyData.offlineDeviceCount}}</p>
                  </el-row>
               </el-row>
            </el-row>
            <el-row class="top-right-bg" style="padding-top: 10px">
               <el-row class="top-right-bottom">
                  <el-row class="title">系统检测</el-row>
                  <el-row class="content">
                     <el-col :span="8" class="text">
                        <p>系统得分：</p>
                        <p>{{score}}分</p>
                        <p>达到同行业偏下水平</p>
                        <button @click="viewReport">点击查看报告>></button>
                     </el-col>
                     <el-col :span="16" class="img">
                        <img src="@/assets/measure.png" alt="">
                        <span>系统检测</span>
                     </el-col>
                  </el-row>
               </el-row>
            </el-row>
         </el-col>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   export default {
      data() {
         return{
            useData: [],
            surveyData: {},  // 概况数据
            score: '',
            index: 1,
            hotelId: '',
            pieOptions: {
               tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
               },
               legend: {
                  orient: 'vertical',
                  left: 10,
                  top: 40,
                  data: []
               },
               color:['#ffa333', '#e84330', '#0272ff', '#41d8e8', '#2fe5ba', '#ee6339', '#9576f0'],
               series: [
                  {
                     name: '数据来源',
                     type: 'pie',
                     radius: '55%',
                     center: ['50%', '60%'],
                     data: [],
                     emphasis: {
                        itemStyle: {
                           shadowBlur: 10,
                           shadowOffsetX: 0,
                           shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                     }
                  }
               ]
            },
         }
      },
      mounted() {
         this.hotelId = this.hotelInfo.id
         this.getUseData()
         this.getDataSurvey()
         this.getSystemScore()
      },
      computed: { ...mapState(['hotelInfo']) },
      methods: {
         // 获取设备使用数据统计
         getUseData(){
            const url = urlObj.useStatistics
            const param = {hotelId: this.hotelId}
            this.$axios.get(url, param).then(res => {
               if (res.records){
                  let useData = res.records
                  this.pieOptions.legend.data = []
                  this.useData = useData.map(item => {
                     let name
                     if (item.index === 'HOSW') name = '智能开关'
                     if (item.index === 'HODR') name = '智能窗帘'
                     if (item.index === 'HOSL') name = '智能锁'
                     if (item.index === 'HOSC') name = '场景面板'
                     if (item.index === 'HOIN:8192') name = '电视'
                     if (item.index === 'HOIN:49152') name = '空调'
                     if (item.index === 'ssm') name = '自助机'
                     this.pieOptions.legend.data.push(name)
                     return { value: item.count, name }
                  })
                  this.pieOptions.series[0].data = this.useData
                  this.$echarts.init(document.getElementById('top-left-pie')).setOption(this.pieOptions);
               }
            })
         },
         // 获取数据概况
         getDataSurvey(){
            const url = urlObj.dataSurvey
            const param = {hotelId: this.hotelId}
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  this.surveyData = res.records
               }
            })
         },
         // 获取系统评分
         getSystemScore(){
            const url = urlObj.systemScore
            const param = { hotelId: this.hotelId }
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  this.score = res.records
               }
            })
         },
         // 查看报告
         viewReport(){}
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.getUseData()
                  this.getDataSurvey()
                  this.getSystemScore()
               }
            },
            deep: true
         }
      },
   }
</script>

<style scoped lang="scss">
   .cont .top{
      .title{
         height:50px;
         line-height: 50px;
         background: #f7f7f7;
         font-size: 18px;
         padding:0 0 0 30px;
      }
      height: 500px;
      .top-left{
         padding: 0 20px 0 0;
         .top-left-bg{
            background-color: #FFFFFF; height: 554px; box-shadow: 0 0 8px #DCE7F9;
         }
      }
      .top-left-list{
         line-height: 46px;
         .el-row{
            width: 80%; padding: 20px; margin: 30px auto 0; border-radius: 8px;
            border: solid 1px #409eff; font-size: 14px;
            p{
               line-height: 36px; border-bottom: 1px solid #409eff;
               b{ color: #409eff; font-size: 15px }
            }
         }
      }
      .top-right{
         height: 560px;
         .top-right-bg{
            height: 50%; padding-bottom: 5px;
            .top-right-top,
            .top-right-bottom {
               background-color: #FFFFFF; height: auto;
               box-shadow: 0 0 8px #DCE7F9;
               h3{ color: #409eff }
               p{ font-size: 14px; margin: 8px 0}
               .text button{ background-color: transparent; border: solid 1px #409eff;
                  border-radius: 12px; padding: 3px 8px; color: #409eff;
               }
               .img{ text-align: center;
                  img{ width: 190px; display: block; margin: 0 auto }
                  span{ font-weight: bold }
               }
               .content{ padding: 20px}
            }
            .top-right-top{
               h3 { color: #409eff; margin-bottom: 10px }
               p { line-height: 26px;font-size: 14px }
            }
            .top-right-bottom{
               p:nth-child(1){ margin-top: 30px }
               p:nth-child(2){ font-size: 30px; font-weight: bold; color: #fe4703 }
               p:nth-child(3){ font-weight: bold }
            }
         }
      }
   }
</style>
